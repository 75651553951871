import React, { Component } from 'react';
import {Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Navbar,Nav,Container} from 'react-bootstrap';


class Design extends Component {
    render() {  
        return(
            <Navbar collapseOnSelect expand="lg" bg="white" variant="dark">
                <Container>
            <Navbar.Brand href="#home"></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto"></Nav>
                <Nav><Link to="/newEditor"><Button className="btn btn-success text-white">Create New Template</Button></Link></Nav>
            </Navbar.Collapse>
            </Container>
            </Navbar>
        );
    }
}
 
export default Design;