import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import EmailEditor from '../../../src';
import axios from 'axios';
import {Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button} from 'react-bootstrap';
import { Navbar,NavDropdown,Nav,Container,Card } from 'react-bootstrap';
import queryString from 'query-string';

const Containers = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  Button {
    margin-left: 15px;
    cursor: pointer;
    color: #fff;
  }
`;

// Saved design function
function writeContent(content, file){
  const data = {filename : file , code : content};
  axios.post('https://beqkreach.smartchoiceai.com/', data)
      .then((response) => console.log(response.data))
      .catch(err => {
        console.error(err);
      });
}

// export to HTMl file function
function exportHtmlfile(content, file){
  axios.post('https://beqkreach.smartchoiceai.com/savehtml', {data : content, filename : file})
      .then((response) => console.log(response.data))
      .catch(err => {
        console.error(err);
      });
}



const Example = (props) => {

  const emailEditorRef = useRef(null);

  useEffect(() => {
    //const filename = queryString.parse(props.location.search.name);
    console.log(props.urlString);
  }, [])

   //ExportTo Html Action
   const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;      
      exportHtmlfile(html, props.urlString)
      alert('Success.');
    });
  };
  
  const exportHtmlFromDesign = (file) => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;      
      exportHtmlfile(html, file)
      alert('Success.');
    });
  };


  //SaveDesign button Action
  const saveDesign = () => {
    emailEditorRef.current.editor.saveDesign((design) => {
    exportHtmlFromDesign(props.urlString);
    writeContent(design, props.urlString);
      alert("Saved");
    });
  };

  const onDesignLoad = (data) => {
    console.log('onDesignLoad', data);
  };

  
  const onLoad = () => {

    axios.post('https://beqkreach.smartchoiceai.com/getSelectedFileContent', {filename : props.urlString})
    .then(
      (response) => 
          emailEditorRef.current.editor.loadDesign(response.data) //Here's the initial design loaded)
    )
    .catch(err => {
      console.log(err);
    });
  };

  return (
    <Containers>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{height: '80px'}}>
          <Container>
            <Navbar.Brand href="#" className="text-dark">
            <b className="display">“</b>There is no formula for the perfect email, Authentic and honest messaging works.”
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto"></Nav>
            <Link to="/">  <Button  title="Close Design" className="btn btn-sm btn-danger"><b>Close</b></Button> </Link>  
            <Button title="Save Design" className="btn btn-sm btn-warning" variant="primary" onClick={saveDesign}>
              <b>Save</b>
            </Button>
            </Navbar.Collapse>
          </Container>
      </Navbar>

          <React.StrictMode>
            <EmailEditor ref={emailEditorRef} onLoad={onLoad} />
          </React.StrictMode>
    </Containers>
  );
};

export default Example;