import React, { Component, useRef } from 'react';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Navbar,NavDropdown,Nav,Container} from 'react-bootstrap';
import EmailEditor from '../../../src';
import { useState } from "react";
import { Modal} from "react-bootstrap";
import axios from 'axios';
import ReactDOM from 'react-dom'



const Containers = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  
  Button {
    margin-left: 15px;
    cursor: pointer;
    color: #fff;
  }
`;

// Saved design function
function writeContent(content, file){
  const data = {filename : file , code : content};
  axios.post('https://beqkreach.smartchoiceai.com/', data)
      .then((response) => console.log(response.data))
      .catch(err => {
        console.error(err);
      });
}

// export to HTMl file function
function exportHtmlfile(content, file){
  axios.post('https://beqkreach.smartchoiceai.com/savehtml', {data : content, filename : file})
      .then((response) => console.log(response.data))
      .catch(err => {
        console.error(err);
      });
}


const DesignEdit = (props) => {

  const [showModal, setShow] = useState(false);
  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showModal1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const ref = useRef(null);

  const exportHtml = () => {
    ref.current.exportHtml((data) => {
      const { design, html } = data;
      exportHtmlfile(html, name2);   //filename pass pannanum 
      alert('Success.');
    });
  }

  const exportHtmlFromDesign = (file) => {
    ref.current.exportHtml((data) => {
      const { design, html } = data;
      exportHtmlfile(html, file);   //filename pass pannanum 
      alert('Success.');
    });
  }

  const saveDesign = () => {
    ref.current.saveDesign((design) => {
      exportHtmlFromDesign(name1);
      writeContent(design, name1); //filename pass pannanum 
      alert("Saved");
    });
  };

  const storeName = (name, flag) => {
    if(flag){setName1(name)}else{ setName2(name)}
  }


 return(
    <Containers>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{height: '40px'}}>
          <Container>
            <Navbar.Brand href="#" className="text-dark">
            <b className="display">“</b>There is no formula for the perfect email, Authentic and honest messaging works.”
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto"></Nav>
            <Button title="Save Design" className="btn btn-success btn-sm" variant="primary" onClick={handleShow}>
              <b>Save</b>
            </Button>
            <Link to="/">  <Button  title="Close Design" className="btn btn-danger btn-sm"><b>Close</b></Button> </Link>  
            </Navbar.Collapse>
          </Container>
      </Navbar>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Template Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
          <div className="form-group">
            <input type="text" className="form-control" value={name1} placeholder="ex : mydesign" onChange={e => storeName(e.target.value, 1)} />
          </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={ function(event){ saveDesign(); handleClose(); } }>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal 2 components */}
      <Modal show={showModal1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Template Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <input type="text" className="form-control" placeholder="ex : mydesigns" id="filename" value={name2} onChange={(e) => storeName(e.target.value, 0)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={ function(event){ exportHtml();  handleClose1(); } }>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <EmailEditor ref={ref} />
    </Containers>
  )
}

export default DesignEdit;
