import React, { useState,  } from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import Bodycard from './example/designList'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import DesignEdit from './dashboard/DesignEdit'
import Example from './example';

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  }

  #demo {
    height: 100%;
  }
`;

const Demo = () => {
  const [urlString, setUrlString] = useState("");
    return (
      <Router>
        <GlobalStyle />
        <Switch>
          <Route path={`/`} exact={true}>
          <Bodycard updateUrl={setUrlString} /> 
          </Route>
          <Route path="/designList/:name">
            <Example urlString={urlString} />
          </Route>
          <Route path={"/newEditor"}>
            <DesignEdit />
          </Route>
        </Switch>
      </Router>
    );
  }

ReactDOM.render(<Demo />, document.querySelector('#demo'));