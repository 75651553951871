import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container,Card,Button,Modal,Row} from 'react-bootstrap';
import {Link } from "react-router-dom";
import Design from './navbarActions';
import bannerImage from './thumbnail.jpg';
import {Tabs, Tab} from 'react-bootstrap-tabs';

const Bodycard = ({updateUrl}) => {
    const [files, setFiles] = useState([]);
    const [qkfiles, getFiles] = useState([]);

    var name = null;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        console.log(localStorage.getItem('campType'));
        axios.get('https://beqkreach.smartchoiceai.com/filesList')
            .then((response) => {
                const bdata = (response);
                console.log(bdata.data);
               setFiles(bdata.data)
        });
        
        // axios.get('https://beqkreach.smartchoiceai.com/filesListOfqkreach')
        //     .then((response) => {
        //         const bdata = (response);
        //         console.log(bdata.data);
        //        getFiles(bdata.data)
        // });

    }, []);

    function nameChange(e){
        name = e.target.value;
        console.log("name is here"+name);
        //this.setState({name: e.target.value});
    }

    function getName(){
        return name;
    }

    function cloneFile(orgName, filename){
        let selectfileContenthtml = null;
        let selectfileContentjson = null;
        
        axios.post('https://beqkreach.smartchoiceai.com/getSelectedFileContentHtml', {filename : filename})
            .then((response) => {
                var bdata = (response);
                console.log(bdata.data);
                selectfileContenthtml = bdata.data;

                axios.post('https://beqkreach.smartchoiceai.com/getSelectedFileContent', {filename : filename})
                .then((response) => {
                    var bdata = (response);
                    console.log(bdata.data);
                    selectfileContentjson = bdata.data;

                    axios.post('https://beqkreach.smartchoiceai.com/savehtml', {data : selectfileContenthtml.toString(), filename : orgName})
                    .then((response) => {
                        var bdata = (response);
                        console.log(bdata.data);
                    });
                    
                    axios.post('https://beqkreach.smartchoiceai.com/', {code : selectfileContentjson, filename : orgName})
                    .then((response) => {
                        var bdata = (response);
                        console.log(bdata.data);
                        location.reload();
                    });
                    alert("success"+name);
                });
        });
    }

    function ShowHtmlContent(filenam) {
        var bdata = null;
        axios.post('https://beqkreach.smartchoiceai.com/getSelectedFileContentHtml', {filename :filenam})
            .then((response) => {
                bdata = (response);
                console.log(bdata.data);

                if(bdata.data !=null)
                {
                    // document.getElementsByClassName(filenam).innerHTML(bdata.data);
                    document.getElementsByClassName(filenam).innerHTML = "<h1>Heloo</h1>";
                    //return bdata.data;
                    //
                }else{
                    document.getElementsByClassName(filenam).innerHTML = "<h1>No preview</h1>";
                    //return "bdata.data";
                    //<div dangerouslySetInnerHTML={{__html: "No Preview"}}/>  
                }
        });
    }

        return(
            <div>
                <br></br>
            <Design/>
            <br/>
        <Container>
            <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                <Tab label="My Email Templates">
                    <br/><br/>
                    <Row className="row row-cols-3">
                        {
                        files.map((file )=> {
                            file = file.split('.')[0];
                            return (
                                <div style={{"marginBottom": '20px'}}>
                                    <div className="col">
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className="text-center text-uppercase" id="Template2">{ file }</Card.Title>
                                            <br/>
                                            {/* <iframe src="card_image.png" height="200" width="300" title="Iframe Example"></iframe>  */}
                                            <img src={bannerImage} className="img-thumbnail" />
                                            {/* <object data="bharathi.html" width="300" height="200"></object> */}
                                            {/* <div className={file}>
                                                {ShowHtmlContent(file)}
                                            </div> */}
                                        </Card.Body>
                                        <Card.Footer>
                                            <div className="float-left">
                                                <Button className="float-right btn-secondary pull-left" onClick={handleShow} style={{"marginLeft": '10px'}}>Clone</Button>
                                            </div>
                                            <div className="float-right">
                                                <Link to={{ pathname: "/designList/"+file }}> 
                                                    <Button className="float-right text-white btn-success" onClick={() => updateUrl(file)}>Edit <span className="glyphicon glyphicon-user"></span></Button>
                                                </Link>
                                            </div>
                                        </Card.Footer>
                                    </Card>

                                    <Modal show={show} onHide={handleClose}>
                                        <Modal.Header closeButton>
                                        <Modal.Title>Campaign Name</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Campaign Name" onChange={(e) => nameChange(e)} />
                                                </div>
                                            </form>
                                        </Modal.Body>
                                        <Modal.Footer>
                                        
                                        <Button variant="primary" onClick={() => cloneFile(getName(), file)}>Save</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                            )
                        })
                    }     
                </Row>
                </Tab>
                <Tab label="QKReach Templates">
                    
                    <br/><br/>
                        <Row className="row row-cols-3">
                            {
                            files.map((file)=> {
                                file = file.split('.')[0];
                                return (
                                    <div style={{"marginBottom": '20px'}}>
                                        <div className="col">
                                        <Card>
                                            <Card.Body>
                                                <Card.Title className="text-center text-uppercase" id="Template2">{ file }</Card.Title>
                                                <br/>
                                                <img src={bannerImage} className="img-thumbnail"/>
                                                {/* <pre>{ShowHtmlContent(file)}</pre> */}
                                            </Card.Body>
                                            <Card.Footer>
                                                <div className="float-right">
                                                    <Link to={{ pathname: "/designList/"+file }}> 
                                                        <Button className="float-right text-white btn-success" onClick={() => updateUrl(file)}>Use <span> </span></Button>
                                                    </Link>
                                                </div>
                                            </Card.Footer>
                                        </Card>

                                        <Modal show={show} onHide={handleClose}>
                                            <Modal.Header closeButton>
                                            <Modal.Title>Campaign Name</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <form>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" placeholder="Campaign Name" onChange={(e) => nameChange(e)} />
                                                    </div>
                                                </form>
                                            </Modal.Body>
                                            <Modal.Footer>
                                            
                                            <Button variant="primary" onClick={() => cloneFile(getName(), file)}>Save</Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </div>
                                )
                            })
                        }     
                    </Row>
                </Tab>
            </Tabs>
        </Container>
        </div>
        );
    }
 
export default Bodycard;